import httpClient from '@/api/httpClient';

const getMarketingCampaignsList = (userId, templateTypes) =>
  httpClient.post(`/api/users/${userId}/template-groups`, [...templateTypes]);

const createCampaign = ({ userId, data }) =>
  httpClient.post(`/api/users/${userId}/template-groups/create`, data);

const deleteCampaign = ({ userId, campaignId }) =>
  httpClient.delete(`/api/users/${userId}/template-groups/${campaignId}`);

export { getMarketingCampaignsList, createCampaign, deleteCampaign };
