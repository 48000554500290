<template>
  <main class="d-flex flex-column white fill-height">
    <header class="header px-5 d-flex justify-space-between align-baseline">
      <div class="d-flex justify-space-between align-baseline">
        <div class="title font-weight-regular">
          Marketing Campaigns
        </div>
        <div class="filter-box mx-6">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Filter"
            single-line
            hide-details
          />
        </div>
      </div>

      <v-spacer />

      <create-campaign-modal @confirm="onCreateCampaign">
        <template v-slot:activator="{ on }">
          <v-btn
            tile
            large
            color="light-blue darken-4"
            class="white--text"
            min-width="166"
            v-on="on"
          >
            New campaign
          </v-btn>
        </template>
      </create-campaign-modal>
    </header>
    <section class="flex-grow-1">
      <data-table
        :headers="$options.marketingCampaignsHeadersConfig"
        :items="marketingCampaigns"
        :options.sync="options"
        :loading="isLoading"
        :search="search"
      >
        <template v-slot:item.title="{ item }">
          <div class="d-flex align-center">
            <img
              class="mr-2"
              src="/icons/marketing-campaigns-icon.svg"
              width="24"
              height="24"
              alt="Campaign ico"
            />
            <span
              class="light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-default"
            >
              {{ item.title }}
            </span>
          </div>
        </template>
        <template v-slot:item.creationDate="{ item }">
          <span class="font-weight-regular text--darken-4">{{ item.creationDate }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <delete-campaign-modal
            :campaign-name="item.title"
            :campaign-id="item.id"
            @confirm="onDeleteCampaign"
          >
            <template v-slot:activator="{ on }">
              <v-btn icon color="red" v-on="on">
                <v-icon :size="20">mdi-delete</v-icon>
              </v-btn>
            </template>
          </delete-campaign-modal>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import { mapGetters } from 'vuex';

import DataTable from '@/components/common/DataTable.vue';
import DeleteCampaignModal from '@/components/campaign/DeleteCampaignModal.vue';
import CreateCampaignModal from '@/components/campaign/CreateCampaignModal.vue';

import {
  getMarketingCampaignsList,
  deleteCampaign,
  createCampaign
} from '@/api/marketingCampaigns.api';

import { formatDateFieldsInArray, compareDates } from '@/utils';

import types from '@/store/types';

const MARKETING_CAMPAIGN_TYPE = 'SKINXS_CAMPAIGN';

const MARKETING_CAMPAIGNS_TABLE_HEADER_CONFIG = [
  {
    text: 'Id',
    value: 'id',
    width: '100px'
  },
  {
    text: 'Title',
    value: 'title',
    width: '500px'
  },
  {
    text: 'Created',
    value: 'creationDate',
    sort: compareDates
  },
  {
    text: 'Actions',
    value: 'actions',
    width: '300px'
  }
];

export default {
  name: 'MarketingCampaignsPage',
  components: { DataTable, DeleteCampaignModal, CreateCampaignModal },
  marketingCampaignsHeadersConfig: MARKETING_CAMPAIGNS_TABLE_HEADER_CONFIG,
  data() {
    return {
      search: '',
      options: {},
      isLoading: false,
      marketingCampaigns: []
    };
  },
  computed: {
    ...mapGetters({
      userId: types.getters.USER_ID
    })
  },
  watch: {
    options() {
      this.getMarketingCampaigns();
    }
  },
  methods: {
    async onDeleteCampaign(campaignId) {
      await deleteCampaign({ campaignId, userId: this.userId });

      this.getMarketingCampaigns();
    },
    async onCreateCampaign(campaignName) {
      await createCampaign({
        userId: this.userId,
        data: {
          title: campaignName,
          type: MARKETING_CAMPAIGN_TYPE
        }
      });

      this.getMarketingCampaigns();
    },
    async getMarketingCampaigns() {
      this.marketingCampaigns = await this.loadMarketingCampaigns();
    },
    transformMarketingCampaigns(campaigns) {
      const campaignsWithFormattedDates = formatDateFieldsInArray(campaigns, ['creationDate']);

      return campaignsWithFormattedDates.map(campaign => {
        return { ...campaign, actions: 'delete' };
      });
    },
    async loadMarketingCampaigns() {
      try {
        this.isLoading = true;

        const { data } = await getMarketingCampaignsList(this.userId, [MARKETING_CAMPAIGN_TYPE]);

        return this.transformMarketingCampaigns(data);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 80px;
}

.filter-box {
  width: 300px;
}
</style>
