var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"d-flex flex-column white fill-height"},[_c('header',{staticClass:"header px-5 d-flex justify-space-between align-baseline"},[_c('div',{staticClass:"d-flex justify-space-between align-baseline"},[_c('div',{staticClass:"title font-weight-regular"},[_vm._v(" Marketing Campaigns ")]),_c('div',{staticClass:"filter-box mx-6"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),_c('v-spacer'),_c('create-campaign-modal',{on:{"confirm":_vm.onCreateCampaign},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text",attrs:{"tile":"","large":"","color":"light-blue darken-4","min-width":"166"}},on),[_vm._v(" New campaign ")])]}}])})],1),_c('section',{staticClass:"flex-grow-1"},[_c('data-table',{attrs:{"headers":_vm.$options.marketingCampaignsHeadersConfig,"items":_vm.marketingCampaigns,"options":_vm.options,"loading":_vm.isLoading,"search":_vm.search},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":"/icons/marketing-campaigns-icon.svg","width":"24","height":"24","alt":"Campaign ico"}}),_c('span',{staticClass:"light-blue--text text--darken-4 font-weight-medium text-decoration-none cursor-default"},[_vm._v(" "+_vm._s(item.title)+" ")])])]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-regular text--darken-4"},[_vm._v(_vm._s(item.creationDate))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('delete-campaign-modal',{attrs:{"campaign-name":item.title,"campaign-id":item.id},on:{"confirm":_vm.onDeleteCampaign},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"red"}},on),[_c('v-icon',{attrs:{"size":20}},[_vm._v("mdi-delete")])],1)]}}],null,true)})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }