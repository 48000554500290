<template>
  <v-dialog v-model="open" max-width="300">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-card class="px-3">
      <v-card-title class="red--text">Delete "{{ campaignName }}" campaign</v-card-title>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onClose(false)">
          Cancel
        </v-btn>
        <v-btn color="red" text @click="onClose(true)">
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteCampaignModal',
  props: {
    campaignId: {
      type: Number,
      required: true
    },
    campaignName: {
      type: String,
      required: true
    }
  },
  data() {
    return { open: false };
  },
  methods: {
    onClose(confirm) {
      if (confirm) {
        this.$emit('confirm', this.campaignId);
      }

      this.open = false;
    }
  }
};
</script>
